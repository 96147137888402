import {ReactNode} from 'react';
import {SimpleForm} from 'react-admin';

interface AsideFormProps {
    children: ReactNode;
}

export const AsideForm = (props: AsideFormProps) => {
    const {children} = props;

    return (
        <SimpleForm toolbar={false} sx={{width: 360}}>
            {children}
        </SimpleForm>
    );
};
