import {Button, CircularProgress} from '@mui/material';

interface Props {
    loading: boolean;
    children: string;
}

export const PublicSubmitButton = (props: Props) => {
    const {loading = false, children} = props;

    return (
        <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            sx={{
                mt: 3,
                mb: 2,
                borderRadius: 3,
                fontWeight: 700,
                textTransform: 'none',
            }}
            disabled={loading}
        >
            {loading ? <CircularProgress size={24} /> : children}
        </Button>
    );
};
