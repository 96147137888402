import {ExpiryDateField} from '@/components/ExpiryDateField';
import {licenseStatusChoices} from '@/lib/choices';
import {
    Datagrid,
    List,
    ReferenceField,
    SelectInput,
    TextField,
} from 'react-admin';

const filters = [
    <SelectInput
        key="status"
        source="status"
        label="Status"
        choices={licenseStatusChoices}
        alwaysOn
    />,
];

export const LicenseList = () => (
    <List filters={filters} perPage={25}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" sortable={false} />
            <ReferenceField
                source="user_id"
                reference="users"
                label="First Name"
                sortable={false}
            >
                <TextField source="first_name" />
            </ReferenceField>
            <ReferenceField
                source="user_id"
                reference="users"
                label="Last Name"
                sortable={false}
            >
                <TextField source="last_name" />
            </ReferenceField>

            <TextField
                source="organisation"
                label="Licence Organisation"
                sortable={false}
            />
            <TextField source="code" label="Licence Number" sortable={false} />
            <ExpiryDateField source="expiry" label="Expiry" sortable={false} />
            <TextField source="status" sortable={false} />
        </Datagrid>
    </List>
);
