import {
    AutocompleteInput,
    Create,
    ReferenceInput,
    SimpleForm,
} from 'react-admin';

export const RuleCreate = () => (
    <Create>
        <SimpleForm>
            <ReferenceInput source="a_dog_breed_id" reference="breeds">
                <AutocompleteInput label="Breed 1" />
            </ReferenceInput>
            <ReferenceInput source="b_dog_breed_id" reference="breeds">
                <AutocompleteInput label="Breed 2" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
