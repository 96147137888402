import {
    Datagrid,
    DeleteButton,
    List,
    ReferenceField,
    TextField,
} from 'react-admin';

export const RuleList = () => (
    <List perPage={25}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" label="ID" sortable={false} />
            <ReferenceField
                source="a_dog_breed_id"
                reference="breeds"
                label="Breed 1"
                sortable={false}
            />
            <ReferenceField
                source="b_dog_breed_id"
                reference="breeds"
                label="Breed 2"
                sortable={false}
            />
            <DeleteButton mutationMode="optimistic" />
        </Datagrid>
    </List>
);
