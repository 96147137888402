import {Toolbar as RAToolbar, SaveButton} from 'react-admin';

/**
 * Customised version of react-admin's Toolbar component to just show a
 * Save button and no Delete button.
 */
export const Toolbar = () => {
    return (
        <RAToolbar>
            <SaveButton />
        </RAToolbar>
    );
};
