import {Box} from '@mui/material';
import {FormEvent, ReactNode} from 'react';

interface Props {
    children: ReactNode;
    onSubmit: (e: FormEvent) => void;
}

export const PublicForm = (props: Props) => {
    const {onSubmit, children} = props;

    return (
        <Box component="form" onSubmit={onSubmit} sx={{mt: 1, width: '100%'}}>
            {children}
        </Box>
    );
};
