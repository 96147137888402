import {publicTheme} from '@/styles/theme';
import {Box, Container, Typography} from '@mui/material';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {ReactNode} from 'react';
import {Helmet} from 'react-helmet-async';

interface Props {
    title: string;
    children: ReactNode;
}

export const PublicPageLayout = (props: Props) => {
    const {title, children} = props;

    return (
        <ThemeProvider theme={createTheme(publicTheme)}>
            <Helmet>
                <title>{title} – BreedBuddies</title>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap"
                    rel="stylesheet"
                />
            </Helmet>

            <Container
                component="main"
                maxWidth={false}
                disableGutters
                sx={{
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'background.paper',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 2,
                        maxWidth: '400px',
                        width: '100%',
                    }}
                >
                    <Box
                        component="img"
                        src="/favicon.png"
                        alt="BreedBuddies Logo"
                        sx={{
                            width: 80,
                            height: 80,
                            mb: 2,
                        }}
                    />
                    <Typography
                        component="h1"
                        variant="h5"
                        sx={{fontWeight: 600}}
                    >
                        {title}
                    </Typography>
                    {children}
                </Box>
            </Container>
        </ThemeProvider>
    );
};
