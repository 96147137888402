import {PublicAlert} from '@/components/Public/PublicAlert';
import {PublicForm} from '@/components/Public/PublicForm';
import {PublicPageLayout} from '@/components/Public/PublicPageLayout';
import {PublicSubmitButton} from '@/components/Public/PublicSubmitButton';
import {useSearchParams} from 'react-router-dom';
import {PublicTextField} from '@/components/Public/PublicTextField';
import {API_URL} from '@/utils/constants';
import axios from 'axios';
import React, {useState} from 'react';

export const ResetPassword = () => {
    const [searchParams] = useSearchParams();

    const email = searchParams.get('email');
    const code = searchParams.get('code');

    const isMalformedUrl = !email || !code;

    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setIsSuccessful(false);

        if (!password) {
            setError('Please enter a new password');
            return;
        }

        try {
            setIsLoading(true);

            const response = await axios.put(`${API_URL}/user/password/reset`, {
                email,
                code,
                password,
            });

            const success = response?.data?.success;

            if (success) {
                setIsSuccessful(true);
            } else {
                throw new Error('There was an error resetting your password');
            }
        } catch (error) {
            if (error instanceof Error) {
                const message = error?.response?.data?.message || error.message;
                setError(message);
            } else {
                setError('An unexpected error occurred');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <PublicPageLayout title="Reset Password">
            {error && (
                <PublicAlert title="Error" message={error} variant="error" />
            )}
            {isSuccessful && (
                <PublicAlert
                    title="Success"
                    message={`Your password has been reset successfully.`}
                    variant="success"
                />
            )}
            {isMalformedUrl && (
                <PublicAlert
                    title="Error"
                    message="There is a problem with the url. Please check the link and try again."
                    variant="error"
                />
            )}
            {!(isMalformedUrl || isSuccessful) && (
                <PublicForm onSubmit={handleSubmit}>
                    <PublicTextField
                        required
                        id="password"
                        label="New Password"
                        name="password"
                        autoComplete="password"
                        type="password"
                        autoFocus
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <PublicSubmitButton loading={isLoading}>
                        Reset Password
                    </PublicSubmitButton>
                </PublicForm>
            )}
        </PublicPageLayout>
    );
};
