import {Alert, AlertTitle} from '@mui/material';

interface Props {
    variant: 'success' | 'error';
    title: string;
    message: string;
}

export function PublicAlert(props: Props) {
    const {title, message, variant} = props;

    return (
        <Alert severity={variant} sx={{width: '100%', mt: 2}}>
            <AlertTitle>{title}</AlertTitle>
            {message}
        </Alert>
    );
}
