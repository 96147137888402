import {getApi} from '@/api/utils';
import {getChangedData} from '@/utils/getChangedData';
import {DataProvider} from 'react-admin';
import {NavigateFunction} from 'react-router-dom';

export const getDataProvider = (navigate: NavigateFunction): DataProvider => ({
    getList: async (resource, params) => {
        const api = getApi(navigate);
        const response = await api.getList(resource, params);
        return response;
    },

    getOne: async (resource, params) => {
        const api = getApi(navigate);
        const data = await api.getOne(resource, params.id);

        return {data};
    },

    getMany: async (resource, params) => {
        const api = getApi(navigate);
        const {data} = await api.getList(resource, params);
        const filteredData = data.filter((item: {id: string | number}) =>
            params.ids.includes(item.id)
        );

        return {data: filteredData};
    },

    getManyReference: async () => {
        console.warn('getManyReference has not been implemented');
        return {data: []};
    },

    create: async (resource, params) => {
        const api = getApi(navigate);
        const data = {...params.data};

        // Remove the `id` field that is added by react-admin
        delete data.id;

        const responseData = await api.create(resource, data);

        return {data: responseData};
    },

    update: async (resource, params) => {
        const api = getApi(navigate);

        /**
         * Calculate the changed data, as react-admin's `update` method
         * passes through all data, even if it hasn't changed.
         */
        const changedData = getChangedData(params.previousData, params.data);
        const data = await api.update(resource, params.id, changedData);

        return {data};
    },

    updateMany: async () => {
        console.warn('updateMany has not been implemented');
        return {data: []};
    },

    delete: async (resource, params) => {
        const api = getApi(navigate);

        const data = await api.delete(resource, params.id);

        return {data};
    },

    deleteMany: async () => {
        console.warn('deleteMany has not been implemented');
        return {data: []};
    },
});
