import {isObject} from './isObject';
import * as Sentry from '@sentry/react';

const isDev = process.env.NODE_ENV === 'development';

const logBreadcrumb = (...params: unknown[]) => {
    if (isDev) return;

    const message = params
        .filter((param) => typeof param === 'string')
        .join(' ');

    const data = params.reduce((acc: Record<string, unknown>, value) => {
        if (isObject(value)) {
            return {...acc, ...value};
        }
        return acc;
    }, {});

    const hasData = Object.keys(data).length > 0;

    Sentry.addBreadcrumb({
        level: 'info',
        message,
        data: hasData ? data : undefined,
    });
};

const logInfo = (...params: unknown[]) => {
    if (isDev) {
        console.log(...params);
    } else {
        logBreadcrumb(...params);
    }
};

/**
 * @param error Typed as Error or unknown as error's from try/catch are
 * typed as unknown
 */
const logError = (error: Error | unknown) => {
    if (isDev) {
        console.error(error);
    } else {
        Sentry.captureException(error);
    }
};

export const Log = {
    /**
     * Logs an error to Sentry in production, or a console.error in development.
     */
    error: logError,

    /**
     * Logs a Sentry info breadcrumb in production, or a console.log in development.
     */
    info: logInfo,

    /**
     * Logs a Sentry info breadcrumb in production.
     */
    breadcrumb: logBreadcrumb,
};
