import {Grid, GridProps} from '@mui/material';
import {ReactElement} from 'react';
import {Labeled} from 'react-admin';

interface LabeledGridItemProps extends GridProps {
    children: ReactElement;
}

export const LabeledGridItem = (props: LabeledGridItemProps) => {
    const {children, ...rest} = props;

    return (
        <Grid {...rest} item>
            <Labeled>{children}</Labeled>
        </Grid>
    );
};
