import type {ReactNode} from 'react';
import {styled} from '@mui/material/styles';
import {Link} from 'react-router-dom';
import {
    AppBar,
    Layout as RALayout,
    CheckForApplicationUpdate,
    TitlePortal,
    AppBarClasses,
} from 'react-admin';

const LogoLink = styled(Link)({
    display: 'flex',
    marginRight: 14,
});

const Logo = styled('img')({
    width: 30,
    height: 30,
});

const CustomAppBar = () => (
    <AppBar>
        <LogoLink to="/">
            <Logo src="/favicon.png" alt="BreedBuddies" />
        </LogoLink>
        <TitlePortal className={AppBarClasses.title} />
    </AppBar>
);

export const Layout = ({children}: {children: ReactNode}) => (
    <RALayout appBar={CustomAppBar}>
        {children}
        <CheckForApplicationUpdate />
    </RALayout>
);
