import {getAuthProvider} from '@/providers/authProvider';
import {getDataProvider} from '@/providers/dataProvider';
import {DogList} from '@/routes/dogs';
import {DogEdit} from '@/routes/dogs/dog';
import {LicenseList} from '@/routes/licenses';
import {LicenseEdit} from '@/routes/licenses/license';
import {LoginPage} from '@/routes/login';
import {ResetPassword} from '@/routes/reset-password';
import {RuleList} from '@/routes/rules';
import {RuleCreate} from '@/routes/rules/ruleCreate';
import {VerifyAccount} from '@/routes/verify-account';
import {adminTheme} from '@/styles/theme';
import DogsIcon from '@mui/icons-material/Pets';
import RuleIcon from '@mui/icons-material/Rule';
import WalletIcon from '@mui/icons-material/Wallet';
import {Admin, CustomRoutes, Resource} from 'react-admin';
import {Route, useNavigate} from 'react-router-dom';
import {Layout} from './Layout';
import {useMemo} from 'react';

export const App = () => {
    const navigate = useNavigate();
    const dataProvider = useMemo(() => getDataProvider(navigate), [navigate]);
    const authProvider = useMemo(() => getAuthProvider(navigate), [navigate]);

    return (
        <Admin
            layout={Layout}
            loginPage={LoginPage}
            dataProvider={dataProvider}
            authProvider={authProvider}
            theme={adminTheme}
        >
            <Resource
                name="licenses"
                icon={WalletIcon}
                list={LicenseList}
                edit={LicenseEdit}
            />
            <Resource
                name="dogs"
                icon={DogsIcon}
                list={DogList}
                edit={DogEdit}
            />
            <Resource
                name="rules"
                icon={RuleIcon}
                list={RuleList}
                options={{label: 'Breed Rules'}}
                create={RuleCreate}
            />

            {/**
             * NOTE: 'users' currently doesn't need to have a page associated with
             * it and is only listed here to specify a `recordRepresentation` value
             * for it as other resources query 'users'.
             */}
            <Resource
                name="users"
                recordRepresentation={(user) =>
                    `${user.first_name} ${user.last_name}`
                }
            />

            {/* --------------------------------------------------------
             *  PUBLIC ROUTES
             *  -------------------------------------------------------- */}

            <CustomRoutes noLayout>
                <Route path="/verify-account" element={<VerifyAccount />} />
                <Route path="/reset-password" element={<ResetPassword />} />
            </CustomRoutes>
        </Admin>
    );
};
