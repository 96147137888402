import dayjs from 'dayjs';
import {DateField, DateFieldProps, useRecordContext} from 'react-admin';

/**
 * A formatted DateField that colors the text if the date is prior to today.
 */
export const ExpiryDateField = (props: DateFieldProps) => {
    const {source = 'expiry', ...rest} = props;

    const record = useRecordContext();
    const date = record?.[source];
    const isBeforeToday = dayjs(date).isBefore(dayjs(), 'day');

    return (
        <DateField
            source={source}
            {...rest}
            sx={(theme) => ({
                color: isBeforeToday ? theme.palette.error.main : undefined,
            })}
        />
    );
};
