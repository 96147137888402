import {alpha, styled} from '@mui/material/styles';
import {
    Button,
    UpdateWithConfirmButtonProps,
    useNotify,
    useRecordContext,
    useResourceContext,
    useUpdate,
} from 'react-admin';

const StyledButton = styled(Button)(({theme}) => ({
    color: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
            backgroundColor: 'transparent',
        },
    },
}));

/**
 * This component is just a quick and dirty copy of react-admin's
 * UpdateWithConfirmButton that removes the confirmation dialog functionality.
 */
export const UpdateWithoutConfirmButton = (
    props: UpdateWithConfirmButtonProps
) => {
    const notify = useNotify();
    const resource = useResourceContext(props);
    const record = useRecordContext(props);

    const {
        data,
        icon,
        label = 'ra.action.update',
        mutationMode = 'pessimistic',
        onClick,
        mutationOptions = {},
        ...rest
    } = props;
    const {
        meta: mutationMeta,
        onSuccess = () => {
            notify('ra.notification.updated', {
                type: 'info',
                messageArgs: {smart_count: 1},
                undoable: false,
            });
        },
        onError = (error: Error | string) => {
            notify(
                typeof error === 'string'
                    ? error
                    : error.message || 'ra.notification.http_error',
                {
                    type: 'error',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                  ? error.message
                                  : undefined,
                    },
                }
            );
        },
        ...otherMutationOptions
    } = mutationOptions;

    const [update] = useUpdate(
        resource,
        {id: record?.id, data, meta: mutationMeta, previousData: record},
        {
            onSuccess,
            onError,
            mutationMode,
            ...otherMutationOptions,
        }
    );

    const handleUpdate = (e) => {
        update(resource, {
            id: record?.id,
            data,
            meta: mutationMeta,
            previousData: record,
        });

        if (typeof onClick === 'function') {
            onClick(e);
        }
    };

    return (
        <StyledButton onClick={handleUpdate} label={label} {...rest}>
            {icon}
        </StyledButton>
    );
};
