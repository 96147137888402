/**
 * Removes the provided `keysToRemove` from the provided `object`.
 */
export const removeKeys = <T extends Record<string, unknown>>(
    object: T,
    keysToRemove: Array<keyof T>
): Partial<T> => {
    const filteredObject: Partial<T> = {...object};

    keysToRemove.forEach((key) => {
        delete filteredObject[key];
    });

    return filteredObject;
};
