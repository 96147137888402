import {Login, TextInput} from 'react-admin';
import {styled} from '@mui/material/styles';
import {Button, CardContent, CircularProgress} from '@mui/material';
import {
    Form,
    required,
    useTranslate,
    useLogin,
    useNotify,
    useSafeSetState,
} from 'ra-core';

const PREFIX = 'RaLoginForm';

const LoginFormClasses = {
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`,
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({theme}) => ({
    [`& .${LoginFormClasses.content}`]: {
        width: 300,
    },
    [`& .${LoginFormClasses.button}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${LoginFormClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
}));

interface LoginFormProps {
    redirectTo?: string;
    className?: string;
}

interface FormData {
    email: string;
    password: string;
}

/**
 * This component is just a direct copy of react-admin's LoginForm
 * component with the only difference being the `username` field is
 * changed to `email`.
 */
const LoginForm = (props: LoginFormProps) => {
    const {redirectTo, className} = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();

    const submit = (values: FormData) => {
        setLoading(true);
        login(values, redirectTo)
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                          ? 'ra.auth.sign_in_error'
                          : error.message,
                    {
                        type: 'error',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                      ? error.message
                                      : undefined,
                        },
                    }
                );
            });
    };

    return (
        <StyledForm
            onSubmit={submit}
            mode="onChange"
            noValidate
            className={className}
        >
            <CardContent className={LoginFormClasses.content}>
                <TextInput
                    autoFocus
                    source="email"
                    label={'Email'}
                    autoComplete="email"
                    validate={required()}
                />
                <TextInput
                    source="password"
                    label={translate('ra.auth.password')}
                    type="password"
                    autoComplete="current-password"
                    validate={required()}
                />

                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                    className={LoginFormClasses.button}
                >
                    {loading ? (
                        <CircularProgress
                            className={LoginFormClasses.icon}
                            size={19}
                            thickness={3}
                        />
                    ) : (
                        translate('ra.auth.sign_in')
                    )}
                </Button>
            </CardContent>
        </StyledForm>
    );
};

const StyledLogin = styled(Login)(({theme}) => ({
    backgroundImage: 'none',
    backgroundColor: theme.palette.secondary.main,
}));

const Logo = styled('img')({
    width: '100%',
    height: '100%',
});

export const LoginPage = () => (
    <StyledLogin avatarIcon={<Logo src="/favicon.png" alt="BreedBuddies" />}>
        <LoginForm />
    </StyledLogin>
);
