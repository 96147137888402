import {API} from '@/api/API';
import {canBeJSONParsed} from '@/utils/canBeJSONParsed';
import {NavigateFunction} from 'react-router-dom';

let api: API | null = null;

export const getApi = (navigate: NavigateFunction) => {
    if (api) return api;

    const storedAuth = localStorage.getItem('auth');

    if (!storedAuth || !canBeJSONParsed(storedAuth)) {
        navigate('/login');
        throw new Error('Need to be logged in');
    }

    const auth = JSON.parse(storedAuth);

    api = new API(auth, navigate);

    return api;
};

export const resetApi = async () => {
    if (api) {
        await api.logout();
    }
    api = null;
};
