/**
 * Helper function to get the changed values from a previous data object
 * and a current data object.
 *
 * @param previousData The existing data
 * @param data The current data
 * @returns Object with only the values that are different from the previous data
 */
export const getChangedData = <T extends Record<string, unknown>>(
    previousData: T,
    data: Partial<T>
) => {
    const changedData: Partial<T> = {};

    for (const [key, value] of Object.entries(data)) {
        if (value !== previousData[key]) {
            changedData[key as keyof T] = value;
        }
    }

    return changedData;
};
